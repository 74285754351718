import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";

function ContactUs(props) {
  return (
    <section className="overflow-x-hidden bg-white">
      <div className="container mx-auto py-20 px-5 flex flex-col items-center justify-center">
        <div className="flex flex-col text-center w-full mb-5">
          <h1 className="sm:text-6xl text-5xl uppercase font-medium title-font  text-rose-300">
            Get in Touch
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base uppercase">
            Whether you have questions about our drinks, want to learn more
            about our mission, or just want to say hi, we'd love to hear from
            you.
          </p>
        </div>

        <div className="flex flex-col text-center w-full mt-12 items-center justify-center">
          <h2 className="sm:text-4xl text-3xl font-medium title-font text-rose-300 mb-4 uppercase">
            DROP A LINE
          </h2>
          <p className="text-center lg:w-2/3 mx-auto sm:text-4xl text-xl leading-relaxed uppercase">
            hello@prttydrnks.com
          </p>
        </div>
      </div>
      <Footer />
    </section>
  );
}

ContactUs.defaultProps = {
  theme: "indigo",
};

ContactUs.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default ContactUs;
