import React, { useEffect, useState } from "react";
import Marquee from "react-marquee-slider";
import { withSize } from "react-sizeme";
import { nanoid } from "nanoid";
import { FaLeaf, FaSeedling, FaRecycle } from "react-icons/fa";

const features = [
  {
    icon: <FaLeaf size={50} className="text-rose-200" />,
    text: "Crafted in Germany",
  },
  {
    icon: <FaSeedling size={50} className="text-rose-200" />,
    text: "Hazelnuts of Turkey",
  },
  {
    icon: <FaRecycle size={50} className="text-rose-200" />,
    text: "Conscious packaging",
  },
  {
    icon: <FaLeaf size={50} className="text-rose-200" />,
    text: "DAIRY FREE drinks ",
  },
  {
    icon: <FaLeaf size={50} className="text-rose-200" />,
    text: "Crafted in Germany",
  },
  {
    icon: <FaSeedling size={50} className="text-rose-200" />,
    text: "Hazelnuts of Turkey",
  },
  {
    icon: <FaRecycle size={50} className="text-rose-200" />,
    text: "Conscious packaging",
  },
  {
    icon: <FaLeaf size={50} className="text-rose-200" />,
    text: "DAIRY FREE drinks ",
  },
];

const Reviews = ({ size, onStartPerformance, onEndPerformance }) => {
  const [key, setKey] = useState(nanoid());

  useEffect(() => {
    setKey(nanoid());
  }, [size, setKey]);

  let scale = 0.5;

  if (size && size.width > 800) {
    scale = 0.65;
  }

  if (size && size.width > 1100) {
    scale = 0.8;
  }

  if (size && size.width > 1400) {
    scale = 1;
  }

  return (
    <Marquee
      key={key}
      velocity={15}
      minScale={1} // Ensure all items have the same scale
      onInit={onStartPerformance}
      onFinish={onEndPerformance}
      resetAfterTries={2} // Resets the animation after 2 tries if items are not aligned
      scatterRandomly={false} // Disable scatterRandomly to align items next to each other
    >
      {features.map((feature, index) => (
        <div
          key={`marquee-example-feature-${index}`}
          className="p-6"
          style={{ padding: `${scale * 25}px` }}
        >
          <div
            className="flex p-4 bg-white  rounded-2xl "
            style={{
              width: `${scale * 350}px`,
              padding: `${scale * 25}px`,
            }}
          >
            <div>
              <p className="text-center uppercase text-lg md:text-2xl text-rose-300 font-light">
                {feature.text}
              </p>
            </div>
            {/* <div className="flex justify-center items-center">
              {" "}
              <img
                src={require("../images/icon.png")}
                alt="Logo"
                className="rectangle justify-center w-20 md:w-20 lg:w-20 xl:w-20"
              />
            </div> */}
          </div>
        </div>
      ))}
    </Marquee>
  );
};

export default React.memo(withSize()(Reviews));
