import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

function DrinkCards() {
  const [selectedDrink, setSelectedDrink] = useState(null);

  const drinks = [
    {
      img: "hazel.png",
      alt: "Hazel Vanilla",
      name: "HAZEL VANILLA",
      nutrients: `Oat milk, Organic hazelnuts from Turkey, Bourbon vanilla.`,
    },
    {
      img: "mint.png",
      alt: "Strawberry Mint",
      name: "STRAWBERRY MATCHA",
      nutrients: `Oat milk, organic matcha & Strawberry, Almond cream.`,
    },
    {
      img: "peach.png",
      alt: "Peach Summer",
      name: "PEACH NECTAR",
      nutrients: `Oat milk, Organic Peach, Almond cream,  Coconut cream.`,
    },
  ];

  const handleCardClick = (index) => {
    setSelectedDrink(index === selectedDrink ? null : index);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
      {drinks.map((drink, index) => (
        <div
          key={index}
          className="relative rounded-xl overflow-hidden shadow-lg cursor-pointer"
          onClick={() => handleCardClick(index)}
        >
          <img
            src={require(`../images/${drink.img}`)}
            alt={drink.alt}
            className={`w-full h-full object-cover transition-opacity duration-300 ${
              selectedDrink === index ? "opacity-20" : "opacity-100"
            }`}
            style={{ aspectRatio: "1 / 1" }}
          />
          <div className="absolute top-3 left-3">
            <button
              className={`text-white text-3xl border-2 border-white hover:bg-rose-200 font-bold p-1 rounded-lg transition-transform duration-500 ${
                selectedDrink === index
                  ? "rotate-90 bg-rose-200"
                  : "bg-rose-200"
              }`}
            >
              <FaPlus />
            </button>
          </div>
          <div className="flex justify-center items-center">
            <span className="absolute bottom-2 mx-auto text-center text-xl md:text-2xl text-white font-bold p-2">
              {drink.name}
            </span>
          </div>
          {selectedDrink === index && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center p-4 bg-white bg-opacity-50">
              <div className="text-rose-300 uppercase text-lg text-center p-4 rounded-lg">
                <p>{drink.nutrients}</p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default DrinkCards;
