import React, { useEffect, useState } from "react";
import Slider from "../components/Slider";
import Features from "../components/Assistence";
import Footer from "../components/Footer";
import Drinks from "../components/Drinks";
import Marquee from "../components/Marquee";
import Slider2 from "../components/Slider2";

const Home = ({ history }) => {
  const [animatedText, setAnimatedText] = useState("HEALTH");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const words = ["HEALTH", "BEAUTY", "FITNESS"];
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % words.length;
      setAnimatedText(words[index]);
    }, 1500); // Change every 1.5 seconds

    return () => clearInterval(interval);
  }, []);

  const handleJoinWaitlist = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="overflow-x-hidden bg-white">
        <span className="block text-4xl text-rose-300 sm:text-5xl md:text-6xl my-2 bg-white text-center ">
          <div className="flex justify-center items-center">
            <h1 className="text-xl cursor-pointer">
              <a href="/">
                <img
                  src={require("../images/white.png")}
                  alt="Logo"
                  className="rectangle w-40  md:w-40 lg:w-48 xl:w-56"
                  height={45}
                />
              </a>
            </h1>
          </div>
        </span>
        <Slider2 />
        <section className="text-gray-600 md:py-10 pt-8 pb-14 body-font bg-white">
          <div className="container mx-auto px-8 flex flex-col items-center">
            <div className="text-center slide-in ">
              <h1 className="text-6xl pt-4 tracking-tight font-extrabold sm:text-7xl md:text-8xl">
                <span className="block text-rose-300 xl:inline">
                  YOUR <span className="text-green-600">ORGANIC</span> <br />
                  <span className="text-green-600">{animatedText}</span> DRINK.
                  <br /> COMING SOON.
                </span>
              </h1>
              <button
                className=" mb-5 bg-rose-300 shadow-lg text-2xl md:text-3xl text-white font-bold py-2 px-4 rounded-full"
                onClick={handleJoinWaitlist}
              >
                JOIN WAITLIST
              </button>
            </div>

            <Drinks />
            <Marquee />
            <Features />

            <section className="bg-white pt-16 slide-in text-center">
              <div className="container mx-auto px-8">
                <p className="text-6xl tracking-tight text-rose-300 font-extrabold sm:text-7xl md:text-8xl">
                  JOIN US IN ELEVATING WHAT A DRINK CAN BE.
                </p>
                <button
                  className=" mb-5 bg-rose-300 shadow-lg text-2xl md:text-3xl text-white font-bold py-2 px-4 rounded-full"
                  onClick={handleJoinWaitlist}
                >
                  JOIN WAITLIST
                </button>
              </div>
            </section>

            <div className="bg-white">
              <Slider />
            </div>
          </div>
        </section>
        <Footer />
    
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-white bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-2xl shadow-2xl  w-11/12 md:w-1/2 relative">
            <button
              className="absolute top-0 right-0 m-4 text-black"
              onClick={closeModal}
            >
              X
            </button>
            <div
              className="border-2 border-rose-200 rounded-3xl"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <iframe
                width="310"
                height="305"
                src="https://25476d00.sibforms.com/serve/MUIFAOKU3ssTglIqf0NBOTlqOTSBYQWdrVAHUf1YHACdeAa3z1D-NC4vuzsNQGkWqNMZ9QekyMqtTdZ_-HV2MVyb_u_voR9GMFXGi88lpOB0OJa3L65QOe6HXqpH0077qNed2ksB5FzBdzQ3bLQ6OpVH84C-U4kQL6XJvMOyTYo9O2iz6vpJqjR_10sY38mtzeeHSr_8E4AJRnlD"
                frameborder="0"
                scrolling="auto"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
