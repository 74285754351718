import Home from "./booking/Home";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";
import Blog from "./components/Blog";
import Blog1 from "./components/Blog1";
import Blog2 from "./components/Blog2";
import Blog3 from "./components/Blog3";
import Blog4 from "./components/Blog4";
import Blog5 from "./components/Blog5";
import Story from "./components/Story";
import About from "./components/About";
import Contact from "./components/Contact";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Toaster />
      <Switch>
        <Route exact path="/" component={Home} />
        {/* <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} /> */}
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/blog1" component={Blog1} />
        <Route exact path="/blog2" component={Blog2} />
        <Route exact path="/blog3" component={Blog3} />
        <Route exact path="/blog4" component={Blog4} />
        <Route exact path="/blog5" component={Blog5} />
        {/* <Route exact path="/story" component={Story} /> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
