import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";

function Assistence2(props) {
  const handleReload = (url) => {
    window.location.href = url;
  };

  return (
    <section className="overflow-x-hidden bg-white">
      <span className="block text-4xl text-rose-300 sm:text-5xl md:text-6xl my-2 bg-white text-center">
        <div className="flex justify-center items-center">
          <h1 className="text-xl cursor-pointer">
            <a href="/">
              <img
                src={require("../images/white.png")}
                alt="Logo"
                className="rectangle w-40 md:w-40 lg:w-48 xl:w-56"
                height={45}
              />
            </a>
          </h1>
        </div>
      </span>

      <div className="container px-5 py-24 mx-auto">
        <div className="flex text-center flex-col w-full mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium uppercase text-rose-300">
            Blog
          </h1>
        </div>
        <div className="flex flex-wrap -m-4">
          <div className="p-4 md:w-1/3">
            <div className="flex rounded-3xl h-full bg-rose-200 p-8 flex-col">
              <div className="flex items-center mb-3">
                <h2 className="text-gray-900 text-3xl uppercase font-medium">
                  The Future of Sustainable Drinks
                </h2>
              </div>
              <img
                src={require("../images/pic.jpg")}
                alt="Sustainable Drinks"
                className="mb-4 rounded-xl"
              />
              <div className="flex-grow">
                <a
                  href="#"
                  onClick={() => handleReload("/blog1")}
                  className="mt-3 text-black text-xl border-1 p-2 border-black rounded-2xl inline-flex items-center"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/3">
            <div className="flex rounded-3xl h-full bg-rose-200 p-8 flex-col">
              <div className="flex items-center mb-3">
                <h2 className="text-gray-900 text-3xl uppercase font-medium">
                  5 Health Benefits of Plant-Based
                </h2>
              </div>
              <img
                src={require("../images/oat.jpg")}
                alt="Plant-Based Proteins"
                className="mb-4 rounded-xl"
              />
              <div className="flex-grow">
                <a
                  href="#"
                  onClick={() => handleReload("/blog2")}
                  className="mt-3 text-black text-xl border-1 p-2 border-black rounded-2xl inline-flex items-center"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/3">
            <div className="flex rounded-3xl h-full bg-rose-200 p-8 flex-col">
              <div className="flex items-center mb-3">
                <h2 className="text-gray-900 text-3xl uppercase font-medium">
                  The Rise of Functional Beverages
                </h2>
              </div>
              <img
                src={require("../images/functional.jpg")}
                alt="Functional Beverages"
                className="mb-4 rounded-xl"
              />
              <div className="flex-grow">
                <a
                  href="#"
                  onClick={() => handleReload("/blog3")}
                  className="mt-3 text-black text-xl border-1 p-2 border-black rounded-2xl inline-flex items-center"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/3">
            <div className="flex rounded-3xl h-full bg-rose-200 p-8 flex-col">
              <div className="flex items-center mb-3">
                <h2 className="text-gray-900 text-3xl uppercase font-medium">
                  How to Incorporate Protein Drinks into Your Diet
                </h2>
              </div>
              <img
                src={require("../images/diet.jpg")}
                alt="Protein Drinks"
                className="mb-4 rounded-xl"
              />
              <div className="flex-grow">
                <a
                  href="#"
                  onClick={() => handleReload("/blog4")}
                  className="mt-3 text-black text-xl border-1 p-2 border-black rounded-2xl inline-flex items-center"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/3">
            <div className="flex rounded-3xl h-full bg-rose-200 p-8 flex-col">
              <div className="flex items-center mb-3">
                <h2 className="text-gray-900 text-3xl uppercase font-medium">
                  Impact of Organic Ingredients Wellness
                </h2>
              </div>
              <img
                src={require("../images/organic.jpg")}
                alt="Organic Ingredients"
                className="mb-4 rounded-xl"
              />
              <div className="flex-grow">
                <a
                  href="#"
                  onClick={() => handleReload("/blog5")}
                  className="mt-3 text-black text-xl border-1 p-2 border-black rounded-2xl inline-flex items-center"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          {/* Add more blog posts as needed */}
        </div>
      </div>

      <Footer />
    </section>
  );
}

Assistence2.defaultProps = {
  theme: "indigo",
};

Assistence2.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Assistence2;
