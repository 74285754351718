import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";

function Assistence2(props) {
  return (
    <>
      <section className="overflow-x-hidden bg-white">
        <span className="block text-4xl text-rose-300 sm:text-5xl md:text-6xl my-2 bg-white text-center ">
          <div className="flex justify-center items-center">
            <h1 className="text-xl cursor-pointer">
              <a href="/">
                <img
                  src={require("../images/white.png")}
                  alt="Logo"
                  className="rectangle w-40  md:w-40 lg:w-48 xl:w-56"
                  height={45}
                />
              </a>
            </h1>
          </div>
        </span>
        <div className="relative ">
          <div className="max-w-3xl mx-auto">
            <div className="my-3 text-black bg-white p-10 rounded-3xl flex flex-col justify-between leading-normal">
              <div className="">
                <h1 href="#" className="text-rose-300 font-bold text-5xl">
                  Impact of Organic Ingredients on Health and Wellness
                </h1>
                <div className="text-sm font-regular text-gray-900 flex"></div>
                <p className="text-base leading-8 my-5">
                  It has now become the cornerstone of healthy living with
                  increasing demand for organic food and beverages. All this led
                  to a situation wherein more and more consumers started
                  reaching for organic products, basically trying to avoid
                  synthetic chemicals, supporting sustainable farming, and
                  improving health in general.
                </p>

                <p className="text-base leading-8 my-5">
                  Nutritional Benefits: Organically grown ingredients are
                  usually denser in some nutrients, specifically in
                  antioxidants, vitamins, and minerals, compared to their
                  conventionally grown counterparts. All these nutrients are
                  vital for good health and protection from oxidative stress and
                  chronic diseases. Organic farming does not entail the use of
                  synthetic pesticides and fertilizers; hence, the products are
                  less likely to harbor chemical residues, making the products
                  even healthier.
                </p>

                <p className="text-base leading-8 my-5">
                  Less Exposure to Antibiotics and Hormones: Organic animal
                  products, including meat and dairy, are raised without
                  antibiotics or growth hormones. It reduces the chance of
                  exposure to bacteria resistant to antibiotics and
                  hormone-related diseases, making organic options a safer
                  choice for consumers.
                </p>

                <p className="text-base leading-8 my-5">
                  Environmental and Ethical Considerations: Another strong side,
                  which is a lot related to organic agriculture, is its
                  influence on the environment. Organic farming, with its
                  practices, improves soil health, reduces pollution, and
                  promotes biodiversity. All these factors ensure a healthier
                  planet and also contribute to food produced with better
                  quality and reared in a manner that makes it safer for human
                  consumption. In most cases, the standards under organic
                  farming also focus on better animal welfare practices, adding
                  to the ethical appeal of organic products.
                </p>

                <p className="text-base leading-8 my-5">
                  Potential Health Benefits: With the consumption of organic
                  products, one can be content that there is a reduction in
                  potential health problems or chronic diseases, and lives a
                  long, salubrious life all by consuming only organic products.
                  By patronizing organic ingredients, consumers may reduce their
                  risk of dangerous additives and preservatives. The fact that
                  such products can be better tasting and of higher quality
                  often leads the consumer to adapt and sustain healthier
                  lifestyles.
                </p>

                <p className="text-base leading-8 my-5">
                  At Prtty Drnks, we strive to use organic ingredients in all
                  our products whenever possible. We believe the benefits of
                  organic ingredients go a long way in our mission to create
                  beverages that are as good for you as they are for the planet.
                </p>
              </div>
              <div className="flex justify-center items-center">
                {" "}
                <img
                  src={require("../images/drinkicon.png")}
                  alt="Logo"
                  className="rectangle justify-center w-10 md:w-20 lg:w-20 xl:w-20"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <Footer />
        </div>
      </section>
    </>
  );
}

Assistence2.defaultProps = {
  theme: "indigo",
};

Assistence2.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Assistence2;
