import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";
import { RiDrinks2Fill } from "react-icons/ri";

function Assistence2(props) {
  return (
    <>
      <section className="overflow-x-hidden bg-white">
        <span className="block text-4xl text-rose-300 sm:text-5xl md:text-6xl my-2 bg-white text-center">
          <div className="flex justify-center items-center">
            <h1 className="text-xl cursor-pointer">
              <a href="/">
                <img
                  src={require("../images/white.png")}
                  alt="Logo"
                  className="rectangle w-40 md:w-40 lg:w-48 xl:w-56"
                  height={45}
                />
              </a>
            </h1>
          </div>
        </span>
        <div className="relative">
          <div className="max-w-3xl mx-auto">
            <div className="my-3 text-black bg-white p-10 rounded-3xl flex flex-col justify-between leading-normal">
              <div className="">
                <h1 className="text-rose-300 font-bold text-5xl">
                  The Future of Sustainable Drinks
                </h1>
                <div className="text-sm font-regular text-gray-900 flex"></div>
                <p className="text-base leading-8 my-5">
                  The beverage industry is going through a sea change at the
                  moment—something that has long been needed and awaited by
                  quickly budding consumer awareness and an urgent demand for
                  sustainability. With mounting environmental issues such as
                  climate change, depletion of resources, and pollution facing
                  the world, attention has recently been paid to those sectors
                  that by tradition have been resource-intensive. Of these, the
                  beverage sector is within the spotlight of the global quest
                  toward more sustainable practices. At Prtty Drnks, we don't
                  view sustainability as some sort of trend or a marketing
                  mammer; it's what our whole production philosophy and strategy
                  of consumer engagement are based on. Our commitment is toward
                  holism with sustainability, from the sourcing of ingredients
                  to packaging and beyond with our production process.
                </p>

                <p className="text-base leading-8 my-5">
                  We start our journey to sustainability through responsible
                  sourcing, both as a practice and a deep value guiding every
                  single operation we do. We partner with farmers and suppliers
                  committed to caring for the environment so we can be sure that
                  the ingredients we source are not only of the highest quality
                  but also grown in ways that protect and enhance the ecosystem.
                  This means farming organically and avoiding the use of
                  pesticides as far as possible through the use of healthier
                  alternatives, promotion of soil health by regenerative
                  agriculture, and encouraging biodiversity by supporting
                  polyculture over monoculture. This way, we can therefore take
                  care of the health of the planet, while at the same time
                  ensuring that our beverages are as pure and natural as
                  possible.
                </p>

                <p className="text-base leading-8 my-5">
                  The other critical area where we are leading the charge in
                  terms of sustainability is in packaging. Plastic bottles have
                  been the core vessels in the beverage industry for decades and
                  are consequently one of the major factors of environmental
                  degradation, littering landscapes, and filling oceans with
                  human waste. Recognizing this need for change, Prtty Drnks has
                  made huge investments in the development and adoption of
                  alternative packaging solutions that are not only eco-friendly
                  but also enhance the overall consumer experience. We are
                  pioneering the use of biodegradable materials that break down
                  naturally without leaving harmful residues, increasing
                  recycled content to reduce demand for virgin resources, and
                  designing innovative pack formats that use less material
                  without any compromise on quality or safety. These are all
                  just one part of our broader actions aimed at minimizing our
                  impact on the environment and elevating industry standards.
                </p>

                <p className="text-base leading-8 my-5">
                  At a larger scale, beyond immediate processes of production,
                  we are equally dedicated to bringing down our carbon
                  footprint. One of the most critical issues of our times is the
                  problems associated with climate change. We at Prtty Drnks
                  believe that every business has a role to play when it comes
                  to making a difference in some small way—switching to
                  renewable sources of energy, such as solar and wind, to run
                  facilities greatly reduces our reliance on fossil fuels and
                  consequent greenhouse gas emissions. We are redesigning our
                  logistics and transportation networks to be more efficient,
                  use less fuel, and pollute less in delivering our products
                  from production to consumers. Offset the remaining emissions
                  We offset these by planting trees or through other
                  environmental programs to ensure carbon capture and
                  restatement of natural ecosystems.
                </p>

                <p className="text-base leading-8 my-5">
                  The future of beverages undeniably has to be sustainable,
                  innovative, and deeply devoted to the nourishment of people
                  and the planet. We are proud at Prtty Drnks to take lead at
                  the forefront of this movement, presenting customers with a
                  better choice for them and a more sustainable future for
                  everyone. By choosing our products, customers are not only
                  refreshing with a cool drink but making a conscious choice for
                  a better environment and generations to come. Together we can
                  make a meaningful difference, one sip at a time.
                </p>
              </div>
              <div className="flex justify-center items-center">
                {" "}
                <img
                  src={require("../images/drinkicon.png")}
                  alt="Logo"
                  className="rectangle justify-center w-10 md:w-20 lg:w-20 xl:w-20"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <Footer />
  
        </div>
      </section>
    </>
  );
}

Assistence2.defaultProps = {
  theme: "indigo",
};

Assistence2.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Assistence2;
