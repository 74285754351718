import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";

function Assistence2(props) {
  const handleReload = (url) => {
    window.location.href = url;
  };

  return (
    <section className="overflow-x-hidden bg-white">
      <span className="block text-4xl text-rose-300 sm:text-5xl md:text-6xl my-2 bg-white text-center">
        <div className="flex justify-center items-center">
          <h1 className="text-xl cursor-pointer">
            <a href="/">
              <img
                src={require("../images/white.png")}
                alt="Logo"
                className="rectangle w-40 md:w-40 lg:w-48 xl:w-56"
                height={45}
              />
            </a>
          </h1>
        </div>
      </span>

      <div className="flex flex-col text-center w-full my-10">
        <h1 className="sm:text-4xl uppercase text-3xl font-medium title-font mb-4 text-rose-300">
          ABOUT US
        </h1>
        <p className="lg:w-2/3 mx-auto uppercase leading-relaxed text-base">
          PRTTY DRNKS is more than just a beverage company—it's a movement
          towards wellness, sustainability, and beauty. Our drinks are crafted
          with the finest organic ingredients, designed to nourish your body
          from the inside out. Whether you're looking for a plant-based protein
          boost, detoxifying blends, or beauty-enhancing vegan collagen, PRTTY
          DRNKS has something for everyone.
        </p>
      </div>
      <div className="flex flex-wrap m-4">
        <div className="p-4 md:w-1/2 w-full">
          <div className="h-full bg-rose-200 bg-opacity-75 px-8 pt-16 pb-10 rounded-2xl overflow-hidden text-center relative">
            <h1 className="title-font uppercase sm:text-2xl text-xl font-medium text-gray-900 mb-3">
              Redefining a drink
            </h1>
            <p className="leading-relaxed uppercase mb-10">
              Our mission is to create drinks that not only taste great but also
              support your health and well-being. We're committed to using only
              the best organic ingredients, free from dairy, gluten, and added
              sugars. Our products are designed to provide the nutrients your
              body needs to thrive.
            </p>
          </div>
        </div>
        <div className="p-4 md:w-1/2 w-full">
          <div className="h-full bg-rose-200 bg-opacity-75 px-8 pt-16 pb-10 rounded-2xl overflow-hidden text-center relative">
            <h1 className="title-font sm:text-2xl uppercase text-xl font-medium text-gray-900 mb-3">
              Sustainability & Transparency
            </h1>
            <p className="leading-relaxed uppercase mb-10">
              At PRTTY DRNKS, we believe in full transparency and
              sustainability. We source our ingredients responsibly, ensuring
              they are organic, ethically sourced, and eco-friendly. From our
              packaging to our product formulations, every aspect of our
              business is designed with the planet in mind.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </section>
  );
}

Assistence2.defaultProps = {
  theme: "indigo",
};

Assistence2.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Assistence2;
