import React, { useEffect, useState } from "react";
import Marquee from "react-marquee-slider";
import { withSize } from "react-sizeme";
import { nanoid } from "nanoid";

const images = [
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
  require("../images/bottlepink.png"),
];

const Reviews = ({ size, onStartPerformance, onEndPerformance }) => {
  const [key, setKey] = useState(nanoid());

  useEffect(() => {
    setKey(nanoid());
  }, [size, setKey]);

  let scale = 1.2; // Start with a larger scale

  if (size && size.width > 800) {
    scale = 1.5; // Increase scale for larger screens
  }

  if (size && size.width > 1100) {
    scale = 1.8;
  }

  if (size && size.width > 1400) {
    scale = 2; // Full-size images on large screens
  }

  return (
    <Marquee
      key={key}
      velocity={15}
      minScale={1}
      onInit={onStartPerformance}
      onFinish={onEndPerformance}
      resetAfterTries={2}
      scatterRandomly={false}
    >
      {images.map((image, index) => (
        <div key={`marquee-example-image-${index}`} className=" mt-10 px-6">
          <div
            className="flex  bg-white "
            style={{
              width: `${scale * 30}px`, // Adjusted width for larger images
              height: `${scale * 40}px`, // Adjusted height for larger images
            }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="rectangle rounded-3xl w-full h-full object-cover"
            />
          </div>
        </div>
      ))}
    </Marquee>
  );
};

export default React.memo(withSize()(Reviews));
