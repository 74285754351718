import React from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import { FaLeaf } from "react-icons/fa";
import { GiFruitBowl, GiMilkCarton, GiWaterRecycling } from "react-icons/gi";

function Features(props) {
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const features = [
    {
      title: "PLANT BASED",
      description: "100% dairy-free, made with plant-based alternatives.",
      icon: (
        <GiMilkCarton
          size={70}
          className="text-rose-300"
          aria-label="Animal Free"
        />
      ),
    },
    {
      title: "VEGAN COLLAGEN",
      description: "Supports skin health and youthfulness.",
      icon: (
        <img
          src={require(`../images/cosmetic.png`)}
          className="w-full h-full rounded-xl object-cover"
        />
      ),
    },
    {
      title: "ORGANIC INGREDIENTS",
      description: "Crafted with the finest organic ingredients.",
      icon: (
        <FaLeaf
          size={70}
          className="text-rose-300"
          aria-label="Organic Ingredients"
        />
      ),
    },
    {
      title: "NUTRIENT RICH",
      description: "Packed with essential nutrients for your lifestyle.",
      icon: (
        <GiFruitBowl
          size={70}
          className="text-rose-300"
          aria-label="Nutrient Rich"
        />
      ),
    },
    {
      title: "WITHOUT SUGAR",
      description: "Naturally sweet, with no added sugars.",
      icon: (
        <img
          src={require(`../images/nosugar.png`)}
          className="w-full h-full rounded-xl object-cover"
        />
      ),
    },
    {
      title: "DETOXIFYING BLENDS",
      description: "Cleanses your body with powerful detoxifiers.",
      icon: (
        <GiWaterRecycling
          size={70}
          className="text-rose-300"
          aria-label="Detoxifying Blends"
        />
      ),
    },
  ];

  return (
    <section className="py-6 rounded-2xl">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-16">
          {features.map((feature, index) => (
            <div
              key={index}
              className="group relative bg-transparent rounded-2xl p-4 flex flex-col items-center text-center"
            >
              <div className="rounded-full flex bg-transparent text-rose-300 justify-center items-center mb-3 w-20 h-20">
                {feature.icon}
              </div>
              <h4 className="text-4xl px-10 md:text-5xl font-semibold text-rose-300 uppercase transition-all duration-500">
                {feature.title}
              </h4>
              <h4 className="  text-md text-rose-300 uppercase transition-all duration-500">
                {feature.description}
              </h4>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

Features.defaultProps = {
  theme: "indigo",
};

Features.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Features;
