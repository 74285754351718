import React, { useEffect, useState } from "react";
import Marquee from "react-marquee-slider";
import { withSize } from "react-sizeme";
import { nanoid } from "nanoid";

const images = [
  require("../images/shhh.png"),
  require("../images/tape.png"),
  require("../images/street.png"),
  require("../images/PaperPink.png"),
  require("../images/paper.png"),
];

const Reviews = ({ size, onStartPerformance, onEndPerformance }) => {
  const [key, setKey] = useState(nanoid());

  useEffect(() => {
    setKey(nanoid());
  }, [size, setKey]);

  let scale = 1; // Start with a smaller scale for mobile screens

  if (size && size.width > 800) {
    scale = 1.2; // Slightly larger for tablet screens
  }

  if (size && size.width > 1100) {
    scale = 1.5; // Larger images for larger screens
  }

  if (size && size.width > 1400) {
    scale = 1.8; // Full-size images on large screens
  }

  return (
    <Marquee
      key={key}
      velocity={30}
      minScale={1}
      onInit={onStartPerformance}
      onFinish={onEndPerformance}
      resetAfterTries={2}
      scatterRandomly={false}
    >
      {images.map((image, index) => (
        <div
          key={`marquee-example-image-${index}`}
          className="p-6"
          style={{ padding: `${scale * 10}px` }}
        >
          <div
            className="flex p-4 bg-white"
            style={{
              width: `${scale * 250}px`, // Adjusted width for different screen sizes
              height: `${scale * 300}px`, // Adjusted height for different screen sizes
              padding: `${scale * 10}px`,
            }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="rectangle rounded-3xl w-full h-full object-cover"
            />
          </div>
        </div>
      ))}
    </Marquee>
  );
};

export default React.memo(withSize()(Reviews));
