import React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";

function Story(props) {
  return (
    <div className="overflow-x-hidden bg-white">
      {/* Logo and Header */}
      <span className="block text-4xl text-rose-300 sm:text-5xl md:text-6xl my-2 bg-white text-center ">
        <div className="flex justify-center items-center">
          <h1 className="text-xl cursor-pointer">
            <a href="/">
              <img
                src={require("../images/white.png")}
                alt="Logo"
                className="rectangle w-40  md:w-40 lg:w-48 xl:w-56"
                height={45}
              />
            </a>
          </h1>
        </div>
      </span>

      {/* Our Story Section */}
      <section className="text-rose-300 body-font bg-white py-16">
        <div className="container mx-auto px-8">
          <div className="flex text-center flex-col w-full mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium uppercase text-rose-300">
              Our mission
            </h1>
          </div>

          {/* Chapter 1 */}
          <div className="flex flex-col md:flex-row items-center mb-12">
            <div className="md:w-1/2 mb-6 md:mb-0">
              <img
                src={require("../images/cherry.png")}
                alt="Vision"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="md:w-1/2 md:pl-10">
              <h2 className="text-4xl uppercase text-rose-300 mb-4">
                WHO WE ARE
              </h2>
              <p className="leading-relaxed text-lg">
                Prtty Drnks started with a simple yet profound vision: to
                redefine the beverage industry by offering products that not
                only taste great but also contribute to a healthier lifestyle.
                We envisioned a world where every drink could be both a pleasure
                and a source of nourishment, made from the finest organic
                ingredients and crafted with care.
              </p>
            </div>
          </div>

          {/* Chapter 2 */}
          <div className="flex flex-col md:flex-row items-center mb-12">
            <div className="md:w-1/2 mb-6 md:mb-0">
              <img
                src={require("../images/mint.png")}
                alt="Future"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="md:w-1/2 md:pl-10">
              <h2 className="text-4xl uppercase text-rose-300 mb-4">
                WHAT WE ARE DOING
              </h2>
              <p className="leading-relaxed text-lg">
                Our journey took us across the globe in search of the best
                ingredients. From the lush farms of Europe to the vibrant fields
                of South America, we partnered with farmers who share our
                commitment to quality and sustainability. Each bottle of Prtty
                Drinks represents our dedication to creating a product that
                respects the earth and supports the well-being of our customers.
              </p>
            </div>
          </div>

          {/* Chapter 3 */}
          <div className="flex flex-col md:flex-row items-center mb-12">
            <div className="md:w-1/2 mb-6 md:mb-0">
              <img
                src={require("../images/hazel.png")}
                alt="Future"
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
            <div className="md:w-1/2 md:pl-10">
              <h2 className="text-4xl uppercase text-rose-300 mb-4">
                HOW WE ARE DOING IT
              </h2>
              <p className="leading-relaxed text-lg">
                As we look to the future, our mission remains the same: to
                continue innovating and delivering the highest quality beverages
                that support a vibrant, healthy lifestyle. We are committed to
                expanding our reach, bringing Prtty Drnks to more people, and
                exploring new ways to enhance the well-being of our community.
                Join us as we continue this exciting journey.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />

      {/* Contact Info */}
      <span className="block text-2xl sm:text-6xl md:text-6xl my-2 bg-white text-center text-rose-300">
        HELLO@PRTTYDRNKS.COM
      </span>
    </div>
  );
}

Story.defaultProps = {
  theme: "indigo",
};

Story.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default Story;
