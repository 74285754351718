import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin, FaInstagram } from "react-icons/fa";

function Footer(props) {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="bg-white text-rose-200 py-10">
      <div className="container mx-auto flex flex-col items-center text-center">
        <div className="mb-6">
          <Link to="/" onClick={scrollToTop}>
            <img
              src={require("../images/white.png")}
              alt="Logo"
              className="w-24 h-auto mx-auto"
            />
          </Link>
        </div>

        <nav className="flex flex-wrap justify-center space-x-8 mb-6">
          {/* <Link
            to="/about"
            className=" text-rose-300 uppercase hover:text-rose-300"
            onClick={scrollToTop}
          >
            About Us
          </Link> */}
          <Link
            to="/blog"
            className=" text-rose-300 uppercase hover:text-rose-300"
            onClick={scrollToTop}
          >
            Blog
          </Link>
          {/* <Link
            to="/contact"
            className=" text-rose-300 uppercase hover:text-rose-300"
            onClick={scrollToTop}
          >
            Contact
          </Link> */}
          <a
            href="https://www.instagram.com/prttydrnks"
            target="_blank"
            rel="noopener noreferrer"
            className=" text-rose-300 hover:text-rose-300"
          >
            <FaInstagram size={24} />
          </a>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
